<template>
  <b-row>
    <b-col cols="12">
      <h2>Notifications</h2>
      <p class="hp-p1-body mb-0">
        Here you can see your new and old notifications.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <p>Work in progress</p>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
};
</script>
