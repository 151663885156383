<template>
  <b-row>
    <b-col cols="12" md="8">
      <h2>Personal Information</h2>
      <p class="hp-p1-body mb-0">
        All your account details are here, you can update them at any time.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Contact Informations</h3>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button
            variant="primary"
            class="btn-ghost"
            v-b-modal.modal-edit-contact
            :disabled="loading"
          >
            Edit
          </b-button>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8 pb-0 pb-sm-0">
          <ul>
            <li>
              <span class="hp-p1-body">Full Name</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="150px" />
                </div>
                <div v-else>
                  {{ profileData.firstName }} {{ profileData.lastName }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Username</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="150px" />
                </div>
                <div v-else>
                  {{ profileData.username }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Email</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="150px" />
                </div>
                <div v-else>
                  {{ profileData.email }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Date of Birth</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="150px" />
                </div>
                <div v-else>
                  {{ profileData.dateofbirth }}
                </div>
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Crypto Wallets</h3>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button
            variant="primary"
            class="btn-ghost"
            v-b-modal.modal-edit-Wallets
            :disabled="loading"
          >
            Edit
          </b-button>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8">
          <ul>
            <li class="mt-18">
              <span class="hp-p1-body">Metamask</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="250px" />
                </div>
                <div v-else>
                  {{ wallets.walletMetamask }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Solana</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="250px" />
                </div>
                <div v-else>
                  {{ wallets.walletSolana }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Wax</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="250px" />
                </div>
                <div v-else>
                  {{ wallets.walletWax }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Ronin</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="250px" />
                </div>
                <div v-else>
                  {{ wallets.walletRonin }}
                </div>
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Discord Profile</h3>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button variant="primary" class="btn-ghost" @click="discordRefresh">
            Refresh
          </b-button>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8">
          <ul>
            <li class="mt-18">
              <span class="hp-p1-body">Discord ID</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="250px" />
                </div>
                <div v-else>
                  {{ discordData.discordId }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Discord Username</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="250px" />
                </div>
                <div v-else>
                  {{ discordData.discordUsername }}
                </div>
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Server Roles</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <div v-if="loading">
                  <b-skeleton width="250px" />
                </div>
                <div v-else>
                  {{ serverRoles }}
                </div>
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <b-modal
      id="modal-edit-contact"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Contact Edit</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="6">
            <b-form-group label="First Name">
              <b-form-input
                type="text"
                v-model="profileData.firstName"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Last Name">
              <b-form-input
                type="text"
                v-model="profileData.lastName"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Username (contact us to edit this field)">
              <b-form-input
                type="text"
                v-model="profileData.username"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Email">
              <b-form-input
                type="email"
                v-model="profileData.email"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8 mb-8">
            <b-form-group label="Date of Birth">
              <b-form-input
                type="date"
                v-model="profileData.dateofbirth"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <div
            class="divider mx-16 border-black-40 hp-border-color-dark-80"
          ></div>

          <b-col cols="12" class="mt-8">
            <b-alert variant="danger" class="hp-alert-1" show fade>
              <div class="d-flex align-items-center">
                <i class="ri-error-warning-fill mr-8" style="zoom: 200%"></i>
                <span>
                  For your safety, please enter your password to confirm.
                </span>
              </div>
            </b-alert>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Password">
              <b-form-input type="password" v-model="password"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              v-if="updating"
              class="btn btn-primary btn-block"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </b-button>
            <b-button
              v-else
              variant="primary"
              block
              :disabled="!password"
              @click.prevent="updateContact()"
            >
              Edit
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-contact')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal
      id="modal-edit-Wallets"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="hp-profile-modal"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Crypto Wallets Edit</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Metamask">
              <b-form-input
                type="text"
                v-model="wallets.walletMetamask"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Solana">
              <b-form-input
                type="text"
                v-model="wallets.walletSolana"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Wax">
              <b-form-input
                type="text"
                v-model="wallets.walletWax"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Ronin">
              <b-form-input
                type="text"
                v-model="wallets.walletRonin"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <div
            class="divider mx-16 border-black-40 hp-border-color-dark-80"
          ></div>

          <b-col cols="12" class="mt-8">
            <b-alert variant="danger" class="hp-alert-1" show fade>
              <div class="d-flex align-items-center">
                <i class="ri-error-warning-fill mr-8" style="zoom: 200%"></i>
                <span>
                  For your safety, please enter your password to confirm.
                </span>
              </div>
            </b-alert>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Password">
              <b-form-input type="password" v-model="password"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button
              v-if="updating"
              class="btn btn-primary btn-block"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </b-button>
            <b-button
              v-else
              variant="primary"
              block
              :disabled="!password"
              @click.prevent="updateWallets()"
            >
              Edit
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-Wallets')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BAlert,
  BTooltip,
  BSkeleton,
  BSkeletonWrapper,
} from "bootstrap-vue";
import userService from "../../../services/user.service";
import discordRoles from "./discordRoles.json";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BAlert,
    BTooltip,
    BSkeleton,
    BSkeletonWrapper,
  },
  data() {
    return {
      loading: false,
      updating: false,
      profileData: {},
      discordData: {},
      serverRoles: "",
      wallets: {},
      password: "",
      oldData: {
        firstName: "",
        lastName: "",
        email: "",
        dateofbirth: "",
        walletMetamask: "",
        walletSolana: "",
        walletWax: "",
        walletRonin: "",
      },
      boxTwo: false,
    };
  },
  async mounted() {
    this.loading = true;
    var username = JSON.parse(localStorage.getItem("user")).username;
    var useruuid = JSON.parse(localStorage.getItem("user")).useruuid;
    var discordId = JSON.parse(localStorage.getItem("user")).discordId;
    await userService.getUserProfile(username, useruuid).then((response) => {
      this.profileData = response.data;

      this.oldData.firstName = this.profileData.firstName;
      this.oldData.lastName = this.profileData.lastName;
      this.oldData.email = this.profileData.email;
      this.oldData.dateofbirth = this.profileData.dateofbirth;
    });
    await userService.getUserDiscord(username, discordId).then((response) => {
      this.discordData = response;
      var roles = response.discordRoles;

      for (var i in roles) {
        var role = roles[i];

        if (discordRoles[role]) {
          this.serverRoles += discordRoles[role] + ", ";
        } else {
          this.serverRoles += role + "Unknow Role, ";
        }
      }
    });
    await userService.getWallets(username, useruuid).then((response) => {
      this.wallets = response.data;

      this.oldData.walletMetamask = this.wallets.walletMetamask;
      this.oldData.walletSolana = this.wallets.walletSolana;
      this.oldData.walletWax = this.wallets.walletWax;
      this.oldData.walletRonin = this.wallets.walletRonin;
    });
    this.loading = false;
  },
  methods: {
    discordRefresh() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "This action will log you out of the portal, you must then log back in via Discord. Are you sure you want to continue?",
          {
            title: "Please Confirm",
            size: "lg",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            cancelVariant: "text",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$router.push("/logout");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    updateWallets() {
      this.updating = true;
      var username = JSON.parse(localStorage.getItem("user")).username;
      var useruuid = JSON.parse(localStorage.getItem("user")).useruuid;
      var password = this.password;
      var walletMetamask = this.wallets.walletMetamask;
      var walletSolana = this.wallets.walletSolana;
      var walletWax = this.wallets.walletWax;
      var walletRonin = this.wallets.walletRonin;

      var old_walletMetamask = this.oldData.walletMetamask;
      var old_walletSolana = this.oldData.walletSolana;
      var old_walletWax = this.oldData.walletWax;
      var old_walletRonin = this.oldData.walletRonin;

      var discordId = JSON.parse(localStorage.getItem("user")).discordId;

      userService
        .verifyPassword(username, password)
        .then((response) => {
          if (response.success) {
            userService
              .updateWallets(
                username,
                useruuid,
                walletMetamask,
                walletSolana,
                walletWax,
                walletRonin,
                old_walletMetamask,
                old_walletSolana,
                old_walletWax,
                old_walletRonin,
                discordId
              )
              .then((response) => {
                this.updating = false;
                this.$bvModal.hide("modal-edit-Wallets");
                this.$bvToast.toast("Wallets updated successfully", {
                  title: "Success",
                  solid: true,
                });
                this.password = "";
              });
          } else {
            this.updating = false;
            this.$bvToast.toast("Incorrect password", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((error) => {
          this.updating = false;
          this.$bvToast.toast("Incorrect password", {
            title: "Error",
            solid: true,
          });
        });
    },

    updateContact() {
      this.updating = true;
      var username = JSON.parse(localStorage.getItem("user")).username;
      var useruuid = JSON.parse(localStorage.getItem("user")).useruuid;
      var password = this.password;
      var firstName = this.profileData.firstName;
      var lastName = this.profileData.lastName;
      var email = this.profileData.email;
      var dateofbirth = this.profileData.dateofbirth;

      var old_firstName = this.oldData.firstName;
      var old_lastName = this.oldData.lastName;
      var old_email = this.oldData.email;
      var old_dateofbirth = this.oldData.dateofbirth;

      var discordId = JSON.parse(localStorage.getItem("user")).discordId;

      userService
        .verifyPassword(username, password)
        .then((response) => {
          if (response.success) {
            userService
              .updateContact(
                username,
                useruuid,
                firstName,
                lastName,
                email,
                dateofbirth,
                old_firstName,
                old_lastName,
                old_email,
                old_dateofbirth,
                discordId
              )
              .then((response) => {
                if (response.success) {
                  var firstName = this.profileData.firstName;
                  var lastName = this.profileData.lastName;
                  var email = this.profileData.email;
                  var dateofbirth = this.profileData.dateofbirth;

                  this.$store
                    .dispatch("auth/login", {
                      username: username,
                      password: password,
                    })
                    .then(
                      () => {
                        this.updating = false;
                        this.$bvModal.hide("modal-edit-contact");
                        this.$bvToast.toast(
                          "Contact updated successfully, refreshing the page in 3 seconds...",
                          {
                            title: "Success",
                            solid: true,
                          }
                        );
                        this.password = "";
                        setTimeout(function () {
                          window.location.reload();
                        }, 3000);
                      },
                      (error) => {
                        //console.log(error);
                      }
                    );
                } else {
                  this.updating = false;
                  this.$bvModal.hide("modal-edit-contact");
                  this.$bvToast.toast("Contact update failed", {
                    title: "Error",
                    variant: "danger",
                    solid: true,
                  });
                }
              })
              .catch((error) => {
                this.updating = false;
                this.$bvModal.hide("modal-edit-contact");
                this.$bvToast.toast("Contact update failed", {
                  title: "Error",
                  variant: "danger",
                  solid: true,
                });
              });
          } else {
            this.updating = false;
            this.$bvToast.toast("Wrong password", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((error) => {
          this.updating = false;
          this.$bvToast.toast("Wrong password", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>
