<template>
  <b-row v-if="isLoading">
    <b-col cols="12">
      <div class="text-center">
        <span class="loader"></span>
        <h4>Loading, please wait...</h4>
      </div>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col cols="12">
      <h2>Inventory</h2>
      <p class="hp-p1-body mb-0">
        Here is your inventory, for some occasions you can have items, they will
        be displayed here.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12" v-if="inventory == null">
      <b-row>
        <b-col cols="12">
          <h3 class="text-center">No items in your inventory</h3>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" v-else>
      <b-row>
        <b-col class="col-lg-4 col-12" v-for="(item, i) in inventory" :key="i">
          <b-card class="hp-card-5">
            <b-row>
              <b-col cols="12">
                <b-row align-v="start" align-h="between" class="mx-0">
                  <b-badge variant="success" class="border-1">{{
                    item.game
                  }}</b-badge>
                </b-row>
              </b-col>

              <b-col cols="12">
                <div class="text-center">
                  <b-link class="d-block">
                    <img
                      :src="item.image"
                      alt="Stock image of a Genopet"
                      style="max-height: 200px"
                    />
                  </b-link>
                </div>

                <b-row>
                  <b-col cols="12" class="mb-24">
                    <h4>
                      {{ item.name }}
                    </h4>
                    <p
                      class="mb-0 hp-text-color-dark-0 hp-caption font-weight-normal"
                    >
                      {{ item.description }}
                    </p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="col-12">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        Code
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="item.code"
                        readonly
                        :type="code_type"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button variant="outline" @click="hideShowCode()">
                          <i class="remix-icon" :class="code_btn_icon"></i>
                        </b-button>
                        <b-button
                          variant="outline"
                          @click="copyCode(item.code)"
                        >
                          <i class="remix-icon ri-file-copy-line"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>

                  <b-col class="col-12">
                    <b-input-group class="mt-3">
                      <b-input-group-prepend is-text>
                        Level
                      </b-input-group-prepend>
                      <b-form-input
                        type="number"
                        v-model="genopet_level"
                        min="1"
                        max="100"
                        debounce="2500"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>

                  <b-col class="col-12">
                    <p class="mt-6 mb-0 text-black-60 text-center">
                      You can update your Genopet level at any time.
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BInputGroup,
  BInputGroupText,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import userService from "../../../services/user.service";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
  },
  data() {
    return {
      isLoading: true,
      inventory: [],
      genopet_level: 1,
      code_type: "password",
      code_btn_icon: "ri-eye-line",
    };
  },
  async mounted() {
    var useruuid = this.$store.state.auth.user.useruuid;
    await userService.getInventory(useruuid).then((res) => {
      this.inventory.push(res.data.genopet);

      this.genopet_level = res.data.genopet.level;

      //console.log(this.inventory);
    });

    // listen for changes in the genopet level
    this.$watch("genopet_level", (newVal, oldVal) => {
      if (newVal != oldVal) {
        var newData = {
          code: this.inventory[0].code,
          level: newVal,
          description: this.inventory[0].description,
          game: this.inventory[0].game,
          image: this.inventory[0].image,
          name: this.inventory[0].name,
          redemption_date: this.inventory[0].redemption_date,
        };

        userService
          .updateInventory(useruuid, "genopet", newData)
          .then((res) => {
            var success = res.success;
            if (success) {
              this.$bvToast.toast("Genopet level updated!", {
                title: "Success!",
                solid: true,
              });
            } else {
              this.$bvToast.toast("Something went wrong!", {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            }
          })
          .catch((err) => {
            this.$bvToast.toast("Something went wrong!", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          });
      }
    });

    this.isLoading = false;
  },
  methods: {
    copyCode(code) {
      navigator.clipboard.writeText(code);
      this.$bvToast.toast("Copied!", {
        title: "Success!",
        solid: true,
      });
    },
    hideShowCode() {
      if (this.code_type == "password") {
        this.code_type = "text";
        this.code_btn_icon = "ri-eye-off-line";
      } else {
        this.code_type = "password";
        this.code_btn_icon = "ri-eye-line";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
