<template>
  <b-row>
    <b-col cols="12">
      <h2>Security Settings</h2>
      <p class="hp-p1-body mb-0">Here you have your security settings.</p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="12" md="6">
          <h3>Change Password</h3>
          <p class="hp-p1-body mb-0">
            Set a unique password to protect your account.
          </p>
        </b-col>

        <b-col cols="12" md="6" class="text-md-right pr-0">
          <b-button variant="primary" to="#" disabled>
            Change Password
          </b-button>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="12" md="6">
          <h3>Refresh Discord Connection</h3>
          <p class="hp-p1-body mb-0">
            Sometimes your Discord account may not sync properly, use this
            button to refresh the connection.
          </p>
        </b-col>

        <b-col cols="12" md="6" class="text-md-right pr-0">
          <b-button variant="primary" @click="discordRefresh()">
            Refresh
          </b-button>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="12" md="6">
          <h3 class="text-danger">Delete my account</h3>
          <p class="hp-p1-body mb-0 text-danger-3">
            This will delete your account and all of your data.
          </p>
        </b-col>

        <b-col cols="12" md="6" class="text-md-right pr-0">
          <b-button variant="danger" disabled> Delete </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BFormCheckbox, BTooltip } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BTooltip,
  },
  methods: {
    discordRefresh() {
      this.$bvModal
        .msgBoxConfirm(
          "This action will log you out of the portal, you must then log back in via Discord. Are you sure you want to continue?",
          {
            title: "Please Confirm",
            size: "lg",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            cancelVariant: "text",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$router.push("/logout");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>
