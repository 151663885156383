<template>
  <b-row>
    <b-col cols="12" md="12">
      <h2>My Application</h2>
      <p class="hp-p1-body mb-0">
        Here you can see your application details and status.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12" class="mb-12 alert-application">
      <b-alert variant="warning" show fade>
        <div class="d-flex align-items-center">
          <i class="ri-error-warning-fill mr-8" style="zoom: 200%"></i>
          <span>
            For your safety, you can only change the games you are interested
            in.
          </span>
        </div>
      </b-alert>
    </b-col>
    <b-col cols="12" md="12">
      <h4 class="mb-32">You are onboarded for the following game(s)</h4>
      <b-row>
        <div v-for="games in userApplication.data.gamesOnboardedFor">
          <b-col cols="12" class="mb-32">
            <b-card-group deck>
              <b-card
                :title="gamesData[games].name"
                style="max-width: 18rem; min-height: 24rem"
              >
                <b-card-text class="hp-p1-body">
                  {{ gamesData[games].description }}
                </b-card-text>

                <template #footer>
                  <b-button
                    variant="primary"
                    class="hp-p1-body btn-block mb-8"
                    :href="'/games/' + games"
                  >
                    <span>More details</span>
                  </b-button>
                  <b-button
                    variant="danger"
                    class="hp-p1-body btn-block"
                    @click=""
                    :id="'tooltip-leave-' + games"
                  >
                    <span>Leave this game</span>
                  </b-button>
                  <b-tooltip
                    :target="'tooltip-leave-' + games"
                    triggers="hover"
                  >
                    Please contact us, this can't be done here for now.
                  </b-tooltip>
                </template>
              </b-card>
            </b-card-group>
          </b-col>
        </div>
      </b-row>
    </b-col>
    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" md="6" class="mb-32">
          <h4>You are interested in the following game(s)</h4>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button
            variant="primary"
            class="btn-ghost"
            v-b-modal.modal-edit-gamesInterestedIn
            :disabled="loading"
          >
            Edit
          </b-button>
        </b-col>
        <div v-for="games in userApplication.data.gamesInterestedIn">
          <b-col cols="12" class="mb-32">
            <b-card-group deck>
              <b-card
                :title="gamesData[games].name"
                style="max-width: 18rem; min-height: 24rem"
              >
                <b-card-text class="hp-p1-body">
                  {{ gamesData[games].description }}
                </b-card-text>

                <template #footer>
                  <b-button
                    variant="primary"
                    class="hp-p1-body btn-block mb-8"
                    :href="'/games/' + games"
                  >
                    <span>More details</span>
                  </b-button>
                  <b-button
                    :disabled="onboardedFor.includes(games)"
                    variant="success"
                    class="hp-p1-body btn-block"
                    @click=""
                    :id="'tooltip-join-' + games"
                  >
                    <span>Apply for this game</span>
                  </b-button>
                  <b-tooltip :target="'tooltip-join-' + games" triggers="hover">
                    Please contact us, this can't be done here for now.
                  </b-tooltip>
                </template>
              </b-card>
            </b-card-group>
          </b-col>
        </div>
      </b-row>
    </b-col>
    <b-modal
      id="modal-edit-gamesInterestedIn"
      title="Edit games interested in"
      size="lg"
      @ok="updateGamesInterestedIn"
      cancel-variant="cancel"
      ok-variant="primary"
      centered
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Game(s) you are interested in"
          >
            <b-form-checkbox
              v-for="option in options"
              v-model="userApplication.data.gamesInterestedIn"
              :key="option.value"
              :value="option.value"
              :aria-describedby="ariaDescribedby"
              name="gamesinterestedin"
            >
              {{ option.text }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BAlert,
  BSkeleton,
  BSkeletonWrapper,
  BCard,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardGroup,
  BTooltip,
} from "bootstrap-vue";
import userService from "../../../services/user.service";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BAlert,
    BSkeleton,
    BSkeletonWrapper,
    BCard,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardGroup,
    BTooltip,
  },
  data() {
    return {
      // get user discord roles from user in local storage
      userApplication: [],
      loading: false,
      onboardedFor: [],
      interestedIn: [],
      gamesData: {
        genopets: {
          name: "Genopets",
          description:
            "Genopets is an alternate reality, massive multiplayer online game that gamifies physical, mental, and emotional health with financial and social rewards through non-fungible tokens.",
        },
        milliononmars: {
          name: "Million on Mars",
          description:
            "Play and Own in the best MMO on any blockchain! Build rovers, construct buildings, scavenge the planet and craft your way to an NFT Empire.",
        },
        axieinfinity: {
          name: "Axie Infinity",
          description:
            "Axie Infinity is a game universe filled with fascinating creatures, Axies, that players can collect as pets. Players aim to battle, breed, collect, raise, and build kingdoms for their Axies.",
        },
        sunflowerland: {
          name: "Sunflower Land",
          description:
            "Farm, Gather, Cook, Raise Animals, Fish, Craft & Trade your NFTs in this RPG based farming simulator.",
        },
        honeyland: {
          name: "Honey Land",
          description:
            "Honeyland is a strategy and resource management game, gaming platform and decentralized exchange where developers and studios build tools, games, items and more into our platform that allows for infinite game and experience possibilities that never get old.",
        },
        legendsofvenari: {
          name: "Legends of Venari",
          description:
            "Legends of Venari is a creature collecting RPG on the Ethereum Blockchain. Across the land of Caerras, the factions of Azule, Talaw, and Vestal compete in the Great Race to find the rarest of Venari.",
        },
        illuvium: {
          name: "Illuvium",
          description:
            "Illuvium is an open-world exploration, NFT creature collector and autobattler game built on the Ethereum Blockchain. Play-to-earn in a graphically-rich sci-fi adventure and conquer the wilderness to help your crash-landed crew flourish.",
        },
        godsunchained: {
          name: "Gods Unchained",
          description:
            "The epic trading card game using Ethereum L2. Play and earn, build, and trade with true card ownership.",
        },
        bigtime: {
          name: "Big Time",
          description:
            "Big Time is a free-to-play, multiplayer action RPG game that combines fast-action combat and adventure through time and space.",
        },
        bigtim: {
          name: "Big Time",
          description:
            "Big Time is a free-to-play, multiplayer action RPG game that combines fast-action combat and adventure through time and space.",
        },
      },
      options: [
        { text: "Sunflower Land", value: "sunflowerland" },
        { text: "Million on Mars", value: "milliononmars" },
        { text: "Genopets", value: "genopets" },
        { text: "Legends of Venari", value: "legendsofvenari" },
        { text: "Honeyland", value: "honeyland" },
        { text: "Axie Infinity", value: "axieinfinity" },
        { text: "Illuvium", value: "illuvium" },
        { text: "Gods Unchained", value: "godsunchained" },
        { text: "Bigtime", value: "bigtime" },
      ],
    };
  },
  mounted() {
    var username = this.$store.state.auth.user.username;
    var useruuid = this.$store.state.auth.user.useruuid;

    userService.getUserApplication(username, useruuid).then(
      (response) => {
        this.userApplication = response;

        if (this.userApplication.data.gamesInterestedIn.includes("bigtim")) {
          this.userApplication.data.gamesInterestedIn =
            this.userApplication.data.gamesInterestedIn.filter(
              (item) => item !== "bigtim"
            );
          this.userApplication.data.gamesInterestedIn.push("bigtime");
        }

        if (this.userApplication.data.gamesOnboardedFor.includes("bigtim")) {
          this.userApplication.data.gamesOnboardedFor =
            this.userApplication.data.gamesOnboardedFor.filter(
              (item) => item !== "bigtim"
            );
          this.userApplication.data.gamesOnboardedFor.push("bigtime");
        }
      },
      (error) => {
        //console.log(error);
      }
    );
  },
  methods: {
    updateGamesInterestedIn() {
      var username = this.$store.state.auth.user.username;
      var useruuid = this.$store.state.auth.user.useruuid;
      var gamesInterestedIn = this.userApplication.data.gamesInterestedIn;

      userService
        .updateUserGamesInterestedIn(username, useruuid, gamesInterestedIn)
        .then(
          (response) => {
            if (response.success) {
              this.updateData();
              this.$bvToast.toast("Updated Games Interested In", {
                title: "Success",
                solid: true,
              });
            } else {
              this.$bvToast.toast("Failed to update Games Interested In", {
                title: "Error",
                solid: true,
              });
            }
          },
          (error) => {
            this.$bvToast.toast("Failed to update Games Interested In", {
              title: "Error",
              solid: true,
            });
            //console.log(error);
          }
        );
    },
    updateData() {
      userService.getUserApplication(username, useruuid).then(
        (response) => {
          this.userApplication = response;
        },
        (error) => {
          //console.log(error);
        }
      );
    },
  },
};
</script>
