var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"hp-profile-menu py-24",staticStyle:{"flex":"0 0 240px"}},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"hp-profile-menu-header mt-16 mt-lg-3 text-center"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-inline-block position-relative"},[_c('div',{staticClass:"avatar-item d-flex align-items-center justify-content-center rounded-circle",staticStyle:{"width":"80px","height":"80px"}},[_c('img',{attrs:{"src":_vm.profileData.avatar}})])])]),_c('h3',{staticClass:"mt-24 mb-4"},[_vm._v(_vm._s(_vm.profileData.username))]),_c('a',{staticClass:"hp-p1-body"},[_vm._v(" "+_vm._s(_vm.profileData.email)+" ")])])]),_c('div',{staticClass:"hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"},[_c('ul',{staticClass:"ml-n1 mx-lg-n12"},[_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/profile' ? ' active' : ''
          }`,attrs:{"to":"/profile"}},[_c('i',{staticClass:"iconly-Curved-User mr-16"}),_c('span',[_vm._v("Personal Information")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/profile/application' ? ' active' : ''
          }`,attrs:{"to":"/profile/application"}},[_c('i',{staticClass:"iconly-Curved-Paper mr-16"}),_c('span',[_vm._v("My Application")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/profile/inventory' ? ' active' : ''
          }`,attrs:{"to":"/profile/inventory"}},[_c('i',{staticClass:"iconly-Curved-Bag mr-16"}),_c('span',[_vm._v("Inventory")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/profile/notifications' ? ' active' : ''
          }`,attrs:{"to":"/profile/notifications"}},[_c('i',{staticClass:"iconly-Curved-Notification mr-16"}),_c('span',[_vm._v("Notifications")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/profile/security' ? ' active' : ''
          }`,attrs:{"to":"/profile/security"}},[_c('i',{staticClass:"iconly-Curved-Setting mr-16"}),_c('span',[_vm._v("Security Settings")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1)])]),_c('div',{staticClass:"hp-profile-menu-footer"},[_c('img',{attrs:{"src":require('@/assets/img/pages/profile/profile_details.svg'),"alt":"Profile Image"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }