<template>
  <b-col class="hp-profile-menu py-24" style="flex: 0 0 240px">
    <div class="w-100">
      <div class="hp-profile-menu-header mt-16 mt-lg-3 text-center">
        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class="avatar-item d-flex align-items-center justify-content-center rounded-circle"
              style="width: 80px; height: 80px"
            >
              <img :src="profileData.avatar" />
            </div>
          </div>
        </div>
        <h3 class="mt-24 mb-4">{{ profileData.username }}</h3>
        <a class="hp-p1-body"> {{ profileData.email }} </a>
      </div>
    </div>

    <div class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0">
      <ul class="ml-n1 mx-lg-n12">
        <li class="mt-4 mb-16">
          <b-link
            to="/profile"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/profile' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-User mr-16"></i>
            <span>Personal Information</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            to="/profile/application"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/profile/application' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Paper mr-16"></i>
            <span>My Application</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            to="/profile/inventory"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/profile/inventory' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Bag mr-16"></i>
            <span>Inventory</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            to="/profile/notifications"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/profile/notifications' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Notification mr-16"></i>
            <span>Notifications</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            to="/profile/security"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/profile/security' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Setting mr-16"></i>
            <span>Security Settings</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
      </ul>
    </div>

    <div class="hp-profile-menu-footer">
      <img
        :src="require('@/assets/img/pages/profile/profile_details.svg')"
        alt="Profile Image"
      />
    </div>
  </b-col>
</template>

<script>
import {
  BCol,
  BBadge,
  BButton,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import userService from "../../../services/user.service";

export default {
  data() {
    return {
      path: "",
      profileData: null,
    };
  },
  components: {
    BCol,
    BBadge,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    $route() {
      this.getRoute();
    },
  },
  methods: {
    getRoute() {
      this.path = this.$route.path;
    },
  },
  created() {
    this.getRoute();
  },
  mounted() {
    var username = JSON.parse(localStorage.getItem("user")).username;
    var email = JSON.parse(localStorage.getItem("user")).email;
    var avatar = JSON.parse(localStorage.getItem("user")).avatar;
    this.profileData = {
      username: username,
      email: email,
      avatar: avatar,
    };
  },
};
</script>
